export const getCategoryGroups = () => [
  {
    key: 'overcategory',
    value: 'Nadkategorie',
  },
  {
    key: 'category',
    value: 'Kategorie',
  },
  {
    key: 'subcategory',
    value: 'Podkategorie',
  },
];

export const categoryGroups = Object.freeze({
  overcategories: 'overcategories',
  categories: 'categories',
  subcategories: 'subcategories',
});
