<template>
  <b-card>
    <form-component :grouped-categories="categories" @onSubmit="onSubmit" @fetchByLocale="fetchByLocale" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/categories-description/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';

import { all, updateDescription } from '@/http/navigation';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      categoryGroups: [],
    };
  },

  created() {
    this.fetchCategories();
  },

  computed: {
    groups() {
      // TODO: Change api to /navigation/navigation-elements and get all elements
      return this.categoryGroups.map((group) => {
        return {
          name: group.name,
          id: group.id,
          description: group.description,
          title: group.title,
        };
      });
    },
    subgroups() {
      // TODO: Change api to /navigation/navigation-elements and get all elements
      return this.categoryGroups
        .map((group) => {
          return group.navigation_subgroups
            .map((subgroup) => {
              return {
                name: subgroup.name,
                id: subgroup.id,
                description: subgroup.description,
                title: subgroup.title,
              };
            })
            .flat();
        })
        .flat();
    },
    elements() {
      // TODO: Change api to /navigation/navigation-elements and get all elements
      return this.categoryGroups
        .map((group) => {
          return group.navigation_subgroups
            .map((subgroup) => {
              return subgroup.navigation_elements
                .map((element) => {
                  return {
                    name: element.name,
                    id: element.id,
                    description: element.description,
                    title: element.title,
                  };
                })
                .flat();
            })
            .flat();
        })
        .flat();
    },
    categories() {
      return {
        overcategory: this.groups,
        category: this.subgroups,
        subcategory: this.elements,
      };
    },
  },

  methods: {
    fetchByLocale(lang) {
      this.fetchCategories(lang);
    },
    fetchCategories(lang = 'pl') {
      all({ lang })
        .then(({ data: { data } }) => {
          this.categoryGroups = data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił problem z pobraniem danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    onSubmit(data) {
      updateDescription(data)
        .then(() => {
          this.showSuccessNotification('Zapisano', 'Zapisano zmiany');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisem',
            'Wystąpił problem z zapisem. Skontaktuj się ze swoim developerem.',
          );
        });
    },
  },
};
</script>
