<template>
  <validation-observer ref="formObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Opisy kategorii</h4>
        <b-form-group label="Grupa kategorii" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz grupę kategorii"
            v-model="categoryGroup"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="categories" />
        </b-form-group>

        <b-form-group label="Kategoria" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="name"
            placeholder="Wybierz kategorię"
            v-model="chosenCategory"
            :clearable="false"
            :deselectFromDropdown="false"
            :disabled="!categoryGroup"
            :options="categoriesByGroup" />
        </b-form-group>

        <b-form-group label="Język">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :disabled="!chosenCategory.id"
              :options="languages" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Tytuł" label-for="v-title">
          <validation-provider #default="{ errors }" name="Tytuł" rules="required|max:150">
            <b-form-input id="v-title" placeholder="Tytuł" v-model="chosenCategory.title" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Opis">
          <validation-provider #default="{ errors }" name="Opis" rules="required">
            <b-form-textarea v-model="chosenCategory.description" placeholder="Opis" rows="3" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-row class="w-100">
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="!lang" type="submit" variant="primary">
            Zapisz
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { getCategoryGroups } from '@/constants/categories';
import { getLanguages } from '@/constants/languages';

export default {
  mixins: [NotificationMixin],
  props: {
    groupedCategories: {
      type: Object,
      default: () => [],
    },
  },

  data() {
    return {
      languages: getLanguages(),
      lang: null,

      categoryGroup: null,
      categories: getCategoryGroups(),

      chosenCategory: {
        id: null,
        name: 'Wybierz kategorię',
        title: '',
        description: '',
      },
    };
  },

  watch: {
    lang() {
      if (!this.lang) return;
      this.$emit('fetchByLocale', this.lang.lang);
    },
    groupedCategories() {
      if (!this.chosenCategory.id) return;

      this.chosenCategory = this.groupedCategories[this.categoryGroup?.key]?.find(
        (el) => el.id === this.chosenCategory.id,
      );
    },
  },

  computed: {
    categoriesByGroup() {
      return this.groupedCategories[this.categoryGroup?.key] ?? [];
    },
  },

  methods: {
    onSubmit() {
      this.$emit('onSubmit', {
        ...this.chosenCategory,
        type: this.categoryGroup.key,
        lang: this.lang.lang,
      });
    },
    validationForm() {
      this.$refs.formObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
